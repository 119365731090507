.container {
    display: flex;
    align-items: center;
    padding: 0 30px;
    gap: 30px;
}

.textBlock {
    text-align: center;
}

.textBlock span{
    color: var(--blueMain);
}
.textBlock h1 {
    --min-font: 25;
    --max-font: 50;
    font-size: clamp(
      var(--min-font) * 1px,
      var(--responsive),
      var(--max-font) * 1px
    );
    text-transform: uppercase;
    padding-bottom: 10px;
}

.textBlock h3 {
    --min-font: 15;
    --max-font: 20;
    font-size: clamp(
      var(--min-font) * 1px,
      var(--responsive),
      var(--max-font) * 1px
    );
    color: var(--greyMain);
}
.certificates {
    width: 50vw;
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.certificates img {
    border-radius: 16px;
}

@media screen and (max-width: 800px) {
  .container {
    flex-direction: column;
    gap: 30px;
  }

  .certificates {
    width: 70vw;
    gap: 10px;
  }
}