.main {
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0px 30px;
  padding: 100px 30px;
  max-width: 1200px;
  background-image: url("../../../../public/images/mainBackground.jpeg");
  background-color: rgba(38, 33, 33, 0.7);
  background-size: cover;
  background-position: bottom;
  background-blend-mode: multiply;
  border-radius: 20px;
  gap: 30px;
  color: white;
}

.main span {
  color: var(--backgroundClr);
}

.description {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.bonuses {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.bonuses h4 {
  text-transform: uppercase;
}

@media screen and (max-width: 800px) {
  .main {
    align-items: center;
  }

  .main h1 {
    font-size: 25px;
  }

  .description p {
    display: none;
  }
}

@media screen and (max-width: 600px) {
  .main {
    margin: 5px;
  }
  .main h1 {
    font-size: 20px;
  }
}
