.about {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}

.about span {
  font-weight: 700;
  color: var(--blueMain);
}

.about h1 {
  margin-top: 50px;
}

.container {
  display: flex;
  flex-direction: column;
  padding: 20px 30px;
  margin: 30px;
  color: var(--greyMain);
  background-color: white;
  border-radius: 20px;
  gap: 30px;
}

.container p {
  line-height: 20px;
}

@media screen and (max-width: 600px) {
  .container {
    margin: 5px;
    padding: 20px 5px;
  }
}
