.sendingForm {
  display: flex;
  gap: 40px;
  flex-direction: column;
  align-items: center;
  padding: 35px;
  width: 60vw;
  background: var(--blueMain);
  border-radius: 16px;
  
}

.sendingForm svg {
  width: 96px;
  height: 96px;
}

.sendingForm svg g {
  fill: white;
}

.sendingForm h2 {
  color: white;
}

.sendingForm h4 {
  color: rgba(222, 248, 153, 1);
  opacity: 0.8;
  font-size: calc(var(--standartFont)*1.4);

}

.inputBlock {
  display: flex;
  width: 100%;
  gap: 30px;
  
}
.email {
  width: 50%;
}

.inputBlock div {
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 5px;
  
}
.sendingForm input {
  border: 1px solid white;
}

.sendingForm input::placeholder {
  color: white;
}

.sendingForm input:focus {
  outline: none;
  /* border: 1px solid white; */
}

.sendingForm input:invalid:not(:placeholder-shown) {
  border: 1px solid rgba(255, 95, 86, 0.8);
}

/* .sendingForm input:-internal-autofill-selected {
  
  background-color: var(--blueMain) !important;
} */

/* .inputBlock input {
  border: 1px dotted white;
}

.inputBlock input::placeholder {
  color: white;
}

.inputBlock input:focus {
  outline: none;
  border: 1px solid white;
}

.inputBlock input:invalid:not(:placeholder-shown) {
  border: 1px solid rgba(255, 95, 86, 0.8);
} */

@media screen and (max-width: 600px) {
  .inputBlock {
    flex-direction: column;
    gap: 10px;
    align-items: center;
  }

  .inputBlock div {
    width: 80%;
  }

  .email {
    width: 80%;
  }

  .sendingForm {
    width: 80vw;
    padding: 20px;
    gap: 10px;
  }


  @media screen and (max-width: 480px) { 
    .inputBlock div {
      width: 100%;
    }

    .email {
      width: 100%;
    }

  }

}
