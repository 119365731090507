.programContent {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.programContentTitle {
  text-align: center;
}

.programContentTitle p {
  max-width: 900px;
  font-size: 18px;
  line-height: 130%;
  text-align: center;
  color: var(--greyMain);
}

.programContentTitle h1 {
  padding-bottom: 20px;
  font-weight: 900;
  font-size: 50px;
  text-transform: uppercase;
}

.programContentTitle span {
  color: var(--blueMain);
}

.programContentWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 40px;
  padding: 30px 10px 10px 10px;
  
}

.programContentWrapper ul li {
  background: url("../../../../../public/images/double-right-arrows.svg") no-repeat
    left center;
  padding: 5px 0px 5px 25px;
  list-style: none;
  margin: 0;
  line-height: 20px;
}



@media screen and (max-width: 1200px) {
  .programContentWrapper {
    flex-direction: column;
  }
}

@media screen and (max-width: 700px) {
  .programContent {
    padding: 0;
    margin: 5px;
  }

  .programContentTitle {
    padding: 10px;
  }

  .programContentTitle h1 {
    font-size: 30px;
  }

}
