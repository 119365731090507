.container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.wrapper {
  margin: 0 40px;
  padding: 30px 0;
  overflow: hidden;
}

.wrapper button:first-child {
  left: 5px;
}

.wrapper button:last-child {
  right: 5px;
}

.carousel {
  overflow: auto;
  cursor: pointer;
  display: flex;
  gap: 50px;
  /* scrollbar-width: none; */
  -ms-overflow-style: none;
}

.carousel::-webkit-scrollbar {
    display: none;
}

@media screen and (max-width: 325px) {
  .wrapper {
    padding: 10px 0;
  }

  .carousel {
    gap: 10px;
  }
}
