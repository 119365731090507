.main {
  padding: 0px 30px;
}

.main h1 {
  padding-bottom: 30px;
  font-weight: 900;
  font-size: 50px;
  text-align: center;
}

.main h1 span {
  color: var(--blueMain);
}

.container {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
}



.container > * {  
  display: flex;
  flex: 1;
  align-items: center;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  
}

.item {
  display: flex;
  gap: 20px;
  height: 100%;
}

.item h3 {
  margin: 10px;
  height: 4vw;
}
.item img {
  min-width: 250px;
  min-height: 250px;
  width: 18vw;
  height: 18vw;
  border-radius: 50%;
}

.item p {
  min-width: 300px;
  color: var(--greyMain);
}

@media screen and (max-width: 800px) {
  .main h1  {
    font-size: calc(var(--standartFont)*3.5);
  }
  .item h3 {
    height: 7vw;
  }
}

@media screen and (max-width: 500px) {

  .item p {
    min-width: 260px;
  }

  .main h1 {
    font-size: calc(var(--standartFont)*1.8);
  }
  .item h3 {
    font-size: calc(var(--standartFont)*1.6);
  }
}