.contactBlock {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 5px;
  align-items: flex-end;
  text-align: right;
}

.addressBlock {
  display: flex;
  align-items: center;
}

.telBlock {
  display: flex;
  float: right;
  align-items: center;
  font-weight: bold;
}

.socialMedia {
  display: flex;
  align-items: center;
  gap: 5px;
}

.contactBlock a {
  text-decoration:  none;
}

.blackText {
  color: black;
}

.whiteText {
  color: white;
}

.contactBlock a:hover {
  opacity: .8;
}

@media screen and (max-width: 500px) {
  .addressBlock {
    font-size: 17px;
  }
}
