.topBar {
  font-size: 16px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 10px 30px;
  gap: 20px;
  justify-content: space-between;
  background-color: #CD9B6C;
  /* background-color: rgb(246, 248, 250); */
}

.noMobileNetworkTxt {
  font-size: 20px;
  flex: 0.99;
  text-align: center;
}

@media screen and (max-width: 600px) {

  .topBar {
    font-size: 20px;
    padding: 10px;
  }

  .topBar .smallLogo {
    margin-left: 20px;
    display: flex;
  }
}

@media screen and (max-width: 800px) {
  .noMobileNetworkTxt {
    display: none;
  }
}


