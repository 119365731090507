.container {
  font-size: calc(var(--standartFont)*1.6);
  position: fixed;
  z-index: 1;
}

.notification { 
  align-items: center;
  margin-bottom: 1rem;
  border-radius: calc(var(--standartFont)*0.4);
  box-shadow: 0 0 10px #999;
  color: #000;
  opacity: 0.9;
  transition: .3s ease;
  
}

.notification:hover {
  box-shadow: 0 0 12px #fff;
  opacity: 1;
}

.toast {
  width: 365px;
  color: #e6e1e1;
  padding: 20px 15px 10px 10px;
}

.container button {
  float: right;
  background: none;
  border: none;
  color: #fff;
  opacity: 0.8;
  cursor: pointer;
}

.title {
  font-weight: 700;
  font-size: 16px;
  text-align: left;
  margin-top: 0;
  margin-bottom: 6px;
  width: 300px;
  height: 18px;
}

.description {
  margin: 0;
  text-align: left;
}

.bottom-right {
  bottom: 1rem;
  right: 1rem;
  animation: toast-in-right .7s;
}

.bottom-left {
  bottom: 1rem;
  left: 1rem;
  animation: toast-in-left .7s;
}

.top-right {
  bottom: 1rem;
  right: 1rem;
  animation: toast-in-right .7s;
}

.top-left {
  bottom: 1rem;
  left: 1rem;
  animation: toast-in-left .7s;
}

.top-left-up {
  top: 1rem;
  left: 1rem;
  animation: toast-top-left .7s;
}

@keyframes toast-in-right {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes toast-in-left {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes toast-top-left {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.9
  }
}
