.container {
  display: flex;
  align-items: center;
}

.main {
  padding: 50px 50px 0 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
}

.signOn {
  padding: 40px 60px;
  width: fit-content;
  display: flex;
  gap: 30px;
  border-radius: 16px;
  background: white;
  align-items: center;
  box-shadow: 1px 1px 20px var(--greyMain);
}

.container img {
  padding-top: 50px;
}

.main h1 {
  --min-font: 25;
  --max-font: 45;
  font-size: clamp(
    var(--min-font) * 1px,
    var(--responsive),
    var(--max-font) * 1px
  );
  text-transform: uppercase;
}

.main p {
  font-size: 17px;
  font-weight: 600;
}

.main span {
  color: var(--blueMain);
}

@media screen and (max-width: 1200px) {
  .container {
    flex-direction: column;
    padding: 0;
    gap: 30px;
  }
}

@media screen and (max-width: 650px) {
  .container {
    padding: 10px 10px 0 10px;
  }

  .main {
    padding: 0;
  }
  .signOn {
    flex-direction: column;
    width: 100%;
    padding: 20px 20px;
    gap: 10px;
  }

  .container img {
    width: 95vw;
  }
}
