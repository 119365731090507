.myModal {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: none;
    background: rgba(0,0,0, 0.5);
    z-index: 6;
}

.myModalContent {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 16px;
    min-width: 300px;
    min-height: 300px;
    max-width: 700px;
    margin: 10px;
    box-shadow: 0 0 20px #fff;
    border: 2px solid rgba(255,255,255, 0.5);
    overflow: hidden;

    
}

.myModal.active {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
}