.feedbackBlock {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    width: 100%;
}

.feedbackBlock h1 {
    font-size: 56px;
    font-weight: 900;
}