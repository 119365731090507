.textCard {
  display: flex;
  flex-direction: column;
  gap: 40px;
  justify-content: space-between;
  width: 100%;
  max-width: 640px;
  height: 360px;
  background: white;
  border-radius: 16px;
  overflow-y: scroll;
  padding: 40px;
}

.textCard::before {
    content: '';
}

.textCard::after {
    content: "";
}
