@import '../fonts/fonts.css';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  --responsive: (var(--min-font) * 1px) + (var(--max-font) - var(--min-font)) *
    ((100vw - 375px) / (1600 - 420));
}
#body {
  height: 100%;
  width: 100%;
  background-color: rgb(246, 248, 250);
}
:root {
  --blueMain: 		#815A36;
  /* --blueMain: #0893f0; */
  --backgroundClr: #E8CAAE;
  --greyMain: #646464;
  --standartFont: 10px;
}

.App {
  font-family: Arial, Helvetica, sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--backgroundClr);
  /* background-color: rgb(246, 248, 250); */
  gap: 50px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-text-fill-color: #ffffff;
    box-shadow: inset 0 0 20px 20px var(--blueMain);
}
