@font-face {
    font-family: 'PixelifySans';
    src: local('PixelifySans'),
    url('./PixelifySans-VariableFont.ttf')
}

@font-face {
    font-family: 'YoungSerif';
    src: local('YoungSerif'),
    url('./YoungSerif-VariableFont.ttf')
}


