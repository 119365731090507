.button {
  padding: 0;
  border: none;
  background: none;
  cursor: pointer;
  opacity: 0.7;
}

.button svg path {
  stroke: lightgray;
}

.previous {
  transform: rotateY(180deg);
}

.active svg path {
  stroke: var(--blueMain);
  opacity: 1;
}

.cardSlider {
  top: calc(50% - 20px);
  position: absolute;
}
