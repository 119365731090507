.priceBlock {
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 30px;
  width: 100vw;
}

.priceBlock h2 {
  font-size: calc(var(--standartFont) * 5);
}

.priceBlock span {
  color: var(--blueMain);
}

@media screen and (max-width: 800px) {
  .priceBlock h2 {
    font-size: calc(var(--standartFont) * 3.5);
  }
}

@media screen and (max-width: 325px) {
  .priceBlock h2 {
    font-size: calc(var(--standartFont) * 2);
  }
}
