.videoFeedback {
  width: 640px;
  height: 360px;
  border-radius: 16px;
}


@media screen and (max-width: 720px) {
    .videoFeedback {
      width: 100%;
    }
  
  }