.switcherContainer {
  display: inline-block;
}

.switcherContainer button {
  cursor: pointer;
  border: none;
  background: none;
  text-transform: lowercase;
  --min-font: 25;
  --max-font: 40;
  font-size: clamp(
    var(--min-font) * 1px,
    var(--responsive),
    var(--max-font) * 1px
  );
}

.videoButton {
  margin-left: 50px;
}

.active {
  color: var(--blueMain);
}
