.button {
  background: white;
  color: var(--blueMain);
  font-size: calc(var(--standartFont) * 1, 8);
  font-weight: 600;
  border-radius: 20px;
  border: 1.5px solid var(--blueMain);
  cursor: pointer;
  padding: 13px 50px;
  transition-duration: 0.4s;
}

.button:hover {
  color: white;
  border: 1.5px solid var(--blueMain);
  background-image: linear-gradient(
    90deg,
    var(--blueMain) 0%,
    #e8caae 20%,
    #d0b59c 40%,
    #d7a372 60%,
    #e8caae 80%,
    var(--blueMain) 100%
  );
  animation: slidebg 5s linear infinite;
}

.blue {
  background: var(--blueMain);
  color: white;
}

@keyframes slidebg {
  to {
    background-position: 20vw;
  }
}
