.slider{
    display: flex;
    gap: 20px;
    align-items: center;
    flex-direction: column;
}

.slider hr {
   border:  1px solid var(--blueMain);
   width: 100%;
   opacity: 0.5;
}

.slide {
     transition: opacity 300ms ease-in-out;
}

.fade-out {
    opacity: 0;
}

.fade-in {
    opacity: 1;
}

.sliderButtons {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

.sliderButtons p {
    font-size: 30px;
}