.container {
    padding: 0 30px;
    padding-bottom: 10px;
    color: white;
    width: 100%;
    background: var(--blueMain);
    /* margin-top: -50px; */
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
   
}

.mainSection {
    padding-top: 10px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.logoAndSocial {
    display: flex;
    flex-direction: column;
    float: left;
    gap: 10px;
}

@media screen and (max-width: 650px) {
    .mainSection {
        flex-direction: column;
        gap: 20px;
    }

    .logoAndSocial {
        align-items: center;
        gap: 10px;
    }
}