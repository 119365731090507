.container {
  width: 100%;
  background: var(--backgroundClr);
}

.content {
  margin: 0 auto;
  max-width: 1680px;
}

.firstSection {
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: relative;
}

.contentDescription {
  padding-left: 156px;
}

.contentDescriptionTitle h3 {
  font-size: 32px;
  text-transform: uppercase;
}

.contentDescriptionTitle h2 {
  font-size: 44px;
  font-weight: 900;
  color: var(--blueMain);
}

.branches {
  margin-top: 10px;
  display: flex;
  gap: 30px;
  justify-content: space-between;
  font-family: YpungSerif;
}

.contentDescriptionList {
  max-width: 600px;
  margin-top: 20px;
  position: relative;
}

.contentDescriptionList h4 {
  text-align: start;
  font-size: 40px;
  font-weight: 700;
  padding-left: 80px;
}

.contentDescriptionList span {
  color: var(--blueMain);
}

.contentDescriptionListItem {
  display: flex;
  align-items: center;
  margin: 10px 0;
}

.contentDescriptionListItem span {
  color: var(--greyMain);
  font-size: 35px;
  font-weight: 600;
  text-transform: uppercase;
  padding: 5px 0;
}

.btn {
  background: none;
  position: absolute;
  z-index: 0;
  animation: blinking 2s linear infinite;
  cursor: pointer;
  z-index: 5;
  border: none;
}

.playButtonContentSection {
  right: -175px;
  top: -120px;
}

.playButtonImageSection {
  right: 10%;
  top: 20px;
  display: none;
}

.contentImage {
  width: 500px;
  height: 900px;
  background-color: var(--blueMain);
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
}

.contentImage img {
  max-width: 100%;
  max-height: 95%;
  position: absolute;
  bottom: 0;
  right: 170px;
}

.contentImage h2 {
  position: absolute;
  font-size: 60px;
  font-weight: 700;
  color: rgba(255, 255, 255, 0.3);
  writing-mode: vertical-rl;
  right: 50px;
  height: 100%;
  text-align: center;
}

.lastSectionContainer {
  display: flex;
  background-color: var(--blueMain);
  padding: 15px 15px 15px 150px;
  gap: 20px;
  max-width: 70%;
  align-items: center;
}

.lastSectionContainer p {
  color: white;
  font-size: calc(var(--standartFont) * 1.6);
  line-height: 170%;
}

@media screen and (max-width: 1440px) {
  .contentDescription {
    padding-left: 40px;
  }
}

@media screen and (max-width: 1320px) {
  .firstSection {
    flex-direction: column;
    align-items: center;
  }

  .playButtonImageSection {
    display: block;
  }

  .playButtonContentSection {
    right: -175px;
    top: -120px;
  }

  .contentDescription {
    padding-left: 0;
  }

  .lastSectionContainer {
    max-width: 90%;
    padding: 15px 15px 15px 5%;
  }
}

@media screen and (max-width: 950px) {
  .playButtonContentSection {
    right: 0%;
    top: -70px;
  }
}

@media screen and (max-width: 800px) {
  .contentDescription {
    padding: 0 10px;
  }

  .contentDescriptionListItem span {
    font-size: 25px;
  }

  .branches {
    justify-content: center;
    gap: 70px;
  }
}

@media screen and (max-width: 569px) {
  .lastSectionContainer {
    max-width: 100%;
  }

  .contentImage img {
    left: -20px;
    width: 70%;
  }

  .contentImage h2 {
    right: 10px;
  }

  .playButtonContentSection {
    /* top: -150px; */
  }

  .contentDescriptionTitle h3 {
    font-size: 25px;
    text-transform: uppercase;
    text-align: center;
  }

  .contentDescriptionTitle h2 {
    font-size: 30px;
    font-weight: 700;
    color: var(--blueMain);
    text-align: center;
  }

  .contentDescriptionList h4 {
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    padding-left: 0px;
  }

  .playButtonContentSection {
    right: calc(50% - 38px);
    top: -100px;
  }

  .contentDescriptionTitle {
    margin-bottom: 105px;
  }
}

@media screen and (max-width: 470px) {
  .playButtonImageSection {
    left: -230px;
    top: 10px;
  }

  .contentImage {
    width: 100%;
    height: 650px;
  }

  .contentImage img {
    height: 90%;
    width: 80%;
    left: 15px;
  }

  .branches {
    gap: 40px;
  }

  .contentDescriptionListItem span {
    font-size: 22px;
  }
}

@keyframes blinking {
  50% {
    opacity: 0.6;
  }
}
