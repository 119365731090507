.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  min-width: 280px;
  max-width: 320px;
  padding-bottom: 50px;
  background-color: white;
  border-radius: 40px 40px 40px 40px;
  user-select: none;
  transition: all 0.2s ease-in-out;
}

.card:hover {
  transform: scale(0.95);
}

.courseDuration {
  height: 140px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  text-align: center;
  background-color: var(--blueMain);
  padding: 0 40px 15px 40px;
  border-radius: 40px 40px 0 0;
  font-weight: 700;
}

.courseDuration h4 {
  width: 90%;
  padding: 10px 0;
  margin-bottom: 20px;
  background-color: white;
  color: white;
  border-radius: 0 0 10px 10px;
}



.body {
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
}

.body p {
  color: var(--greyMain);
}

.description {
  display: flex;
  height: 150px;
  align-items: center;
}

.textWithIcon {
  display: flex;
  align-items: center;
  gap: 20px;
}

@media screen and (max-width: 350px) {
  .card {
    min-width: 240px;
  }
}

.red  h4{
  background-color: rgb(225, 83, 83);
}

.purple h4{
  background-color: rgb(80, 6, 58);
}

.orange h4{
  background-color: rgb(111, 76, 11);
}

.green h4{
  background-color: rgb(9, 99, 41);
}

.white h4 {
  color: var(--blueMain);
}
