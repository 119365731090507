.imagesContainer {
  display: grid;
  grid-template-areas:
    "two one"
    "two four "
    "three four";
  gap: 30px;
}

.gridImage {
  border-radius: 10px;
}

.gridImage:nth-child(1) {
  grid-area: one;
}
.gridImage:nth-child(2) {
  grid-area: two;
}
.gridImage:nth-child(3) {
  grid-area: three;
}
.gridImage:nth-child(4) {
  grid-area: four;
  width: 300px;
  height: 300px;
}

@media screen and (max-width: 1200px) {
  .imagesContainer {
    gap: 10px;
  }
}

@media screen and (max-width: 700px) {
  .imagesContainer {
    display: none;
  }
}
