.container {
  position: fixed;
  bottom: 0px;
  z-index: 1;
}

.connection {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border-top: 1px solid var(--blueMain);
  width: 100vw;
  background-color: white;
  padding: 15px 10px; 
  font-size: 25px;
}

.offline {
  animation: showMessage 0.4s ease-in;
  color: red;
}

.online {
  animation: hideMessage 0.4s ease-out 1.5s;
  animation-fill-mode: forwards;
  margin: 0 10px;
  color: green;
}

@keyframes showMessage {
  from {
    transform: translateY(100%);
    opacity: 0.5;
  }
  to {
    transform: translateY(0%);
    opacity: 1;
  }
}

@keyframes hideMessage {
  from {
    transform: translateY(0%);
    opacity: 1;
  }
  to {
    transform: translateY(100%);
    opacity: 0;
    display: none;
  }
}
